import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'
import List from '../components/Common/List'

import * as s from '../pages/news.module.scss'

const BimPage: React.FC = () => {
  return (
    <>
      <SEO
        title="BIM 360 Docs, BIM modeling in Dubai, UAE"
        description="Signax provides high-quality building information modeling or BIM services in all sectors of construction. We also provide BIM consulting both within Dubai and throughout the UAE"
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          BIM 360 document management & BIM modeling
        </Typography>
        <Typography variant="h2" color="blue">
          What is BIM?
        </Typography>
        <Typography variant="body1">
          BIM (Building Information Modeling) is a technology for the whole
          cycle of construction projects: design, construction, operation
          stages. Project information is created and edited on base of digital
          prototype (virtual copy) of a building or structure being built or
          operated. This technology is based on an attributive 3D model
          consisting of elements (walls, ceilings, pipes, equipment, etc.)
          corresponding to future construction objects. Any information: the
          price of these objects, their sizes, material, the status of
          logistics, documents linked to them, - all these items are filled in
          the attributes of the corresponding elements or linked to them by
          their id (guid).
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/building-information-modeling/image-1.png"
            alt="Building Information Modeling"
            title="Building Information Modeling"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Today, BIM is most widely used in design, since it primarily evolved
          from CAD (Computer Aided Design) software. There is a large selection
          of tools that represent BIM technology for architects and engineers
          around the world. But BIM is intended not only for design automation
          and obtaining its better quality, but also for applying BIM data to
          the next stages of project implementation, especially construction,
          which operates with much bigger budgets and needs to be effective.
        </Typography>
        <Typography variant="h2" color="blue">
          BIM 360 Document Management
        </Typography>
        <Typography variant="body1">
          CDE (Common Data Environment) is an important part of BIM
          collaboration. This is a unified digital space for interaction of all
          stakeholders in the construction process, including file exchange,
          document approval and issue management on 2D documents or using BIM
          models.
        </Typography>
        <Typography variant="body1">
          BIM 360 Docs is the world's most widely used cloud-based CDE system
          for construction today. Since 2021, it has been renamed as ACC Docs
          (Autodesk Construction Cloud Docs). This system allows you to organize
          a complete electronic document management at the construction site and
          it supports BIM.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/building-information-modeling/image-2.png"
            alt="BIM 360 Document Management"
            title="BIM 360 Document Management"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          BIM 360 Docs (ACC Docs) has gained wide popularity due to the open
          API, using which any IT developer from all over the world can write
          its own web application. It will be fully integrated with the CDE as a
          platform.
        </Typography>
        <Typography variant="body1">
          SIGNAX is a software working on the base of BIM 360 (ACC). Its
          integration with BIM 360 allows users to work comfortably in the same
          window. The workflow for users looks like connecting an additional
          function to a familiar solution. All access settings, permissions and
          existing projects are synchronized between both systems. As you create
          a new project in BIM 360, it instantly appears in SIGNAX.
        </Typography>
        <Typography variant="body1">
          SIGNAX expands Autodesk Construction Cloud solutions to meet the needs
          of developers and construction site demands.
        </Typography>
        <Typography variant="h2" color="blue">
          BIM benefits
        </Typography>
        <List className="pl-2">
          <li>High quality submittals</li>
          <li>Exact scopes and valuable information from BIM model</li>
          <li>Construction schedule and budgets (4D and 5D) visualization</li>
          <li>Issues in a digital way connected to the model elements</li>
          <li>Remote visualization of completed works</li>
        </List>
        <Typography variant="body1">
          One of the main advantages of BIM, which was the first thing that
          designers discovered for themselves, was high-quality submittals. BIM
          allows the development of better 2D drawings, consistent with each
          other and without any collisions. The designer does not make human
          factor mistakes with the help of BIM. For example, windows on the plan
          and facade are located differently, the opening is not moved after the
          moved pipe, the door does not open because it is located against the
          landing or suspended ceiling. All this is out of the question.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/building-information-modeling/image-3.png"
            alt="BIM benefits"
            title="BIM benefits"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          BIM model also allows you to get the exact scope of work and materials
          quickly. This is especially useful when there are changes in the
          project and you need to recalculate, update new volumes and see what
          has changed. Previously, each party Quantity Surveyor had to
          recalculate the amount of work multiple times using PDF drawings (he
          didn’t trust the numbers in the spreadsheets). All this took a lot of
          time and effort at each stage. Now all the specialists look at the
          same model, get the necessary data in seconds and see where the scopes
          come from. All changes in the process also become transparent to
          everyone.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={512}
            src="../assets/images/solutions/building-information-modeling/image-4.png"
            alt="BIM benefits 2"
            title="BIM benefits 2"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          When starting work on a construction site, BIM is useful for linking
          the construction schedule with the model, to obtain 4D (time) and 5D
          (budgets) visualization.
        </Typography>
        <Typography variant="body1">
          The function of linking issues to BIM model elements is needed during
          the active work on the construction site. Project issues are much more
          visible than in the spreadsheet, when the Customer sees them on one
          screen. It is more convenient to control and navigate between issues
          in 3D: there is confidence that nothing can be lost.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/building-information-modeling/image-5.png"
            alt="BIM benefits 3"
            title="BIM benefits 3"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Another advantage of BIM technology for construction is the overview
          of completed elements; marking and visualizing of the works performed
          according to the model and obtaining the completed work scopes. It is
          easy to show it to the Customer with the help of BIM model. This can
          be the 100% reason for performed works payment. The process allows to
          increase the transparency of construction, to remove disputes at
          meetings and speed up the approving of contractors invoices.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/building-information-modeling/image-6.png"
            alt="BIM benefits 4"
            title="BIM benefits 4"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          BIM standards
        </Typography>
        <Typography variant="body1">
          BIM standard is a set of corporate documents that describes the rules
          for applying BIM technology within a certain company. It describes the
          naming rules, modeling rules and much more. Design companies apply
          their own best BIM practices if there are no specific BIM requirements
          from the Customer.
        </Typography>
        <Typography variant="body1">
          EIR (Employer Information Requirements) - Customer’s BIM requirements,
          which describe the goals and objectives of applying BIM technology on
          the project (its BIM-uses). Requirements for the composition and
          content of information models are created on base of this. The
          document also describes the project activities, data transfer methods
          and the interaction between various project stakeholders.
        </Typography>
        <Typography variant="body1">
          BEP (BIM Execution Plan) - the document defines the exact project
          stakeholders roles, their permissions and contacts. The document also
          describes project software and other project dependent things. BEP
          should also outline all EIR deviations, approved by the Customer.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={512}
            src="../assets/images/solutions/building-information-modeling/image-7.png"
            alt="BIM standards"
            title="BIM standards"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          BIM consulting
        </Typography>
        <Typography variant="body1">
          Companies often hire BIM consultants to help organize processes and
          implement BIM. Such consultants may be companies or individuals. BIM
          consultants help prepare BIM standards and train employees in usage of
          BIM software. BIM consultants can help with the definition of IT tools
          required for the selected BIM uses and help with adapting them to the
          specific tasks. BIM consultants are often able to develop software
          plugins or Dynamo scripts.
        </Typography>
        <Typography variant="body1">
          BIM consultants can develop solutions to integrate BIM with other
          company processes, link data from designers with procurement,
          construction or operation stages.
        </Typography>
        <Typography variant="body1">
          SIGNAX acts as BIM consulting company. Our specialists are located in
          Dubai, UAE and are ready to come to the Clients office to share our
          experience and answer all the questions. We are also ready to contact
          you remotely by ZOOM at any convenient time.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/building-information-modeling/image-8.png"
            alt="BIM consulting"
            title="BIM consulting"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          The Customer often has no existing BIM requirements prepared for an
          already started project, but he doesn't want to wait for the next
          project to start using BIM. In such cases, BIM consulting companies
          can help with modeling from 2D CAD drawings in order to get a BIM
          model. This can work if project designers have not yet switched to BIM
          and act according to the classical workflows.
        </Typography>
        <Typography variant="h2" color="blue">
          BIM modeling
        </Typography>
        <Typography variant="body1">
          BIM modeling can be used to solve tasks of design verification and
          project audit: simulation of designed 2D drawings and collisions
          checking. Another goal is to get the exact scope of works or 5D
          visualization. In other words, you can have all the advantages of BIM
          for your existing project right now!
        </Typography>
        <Typography variant="body1">
          BIM model can also be used as a digital twin of the facility, for
          example, for reconstruction or repairing, as well as to integrate the
          BIM data to enterprise asset management (EAM) software.
        </Typography>
        <Typography variant="body1">
          SIGNAX performs all these works. We are ready to provide
          preconstruction and construction site works using the power of BIM;
          scanning and developing as-built models. We are also ready for each
          kind of modeling works, performing collision checks, managing project
          digital data and providing BIM support for all the stages.
        </Typography>
        <div className={s.imageWrap}>
          <div className="flex">
            <StaticImage
              width={330}
              src="../assets/images/solutions/building-information-modeling/image-9.png"
              alt="BIM modeling 1"
              title="BIM modeling 1"
              placeholder="blurred"
            />
            <StaticImage
              width={330}
              src="../assets/images/solutions/building-information-modeling/image-10.png"
              alt="BIM modeling 2"
              title="BIM modeling 2"
              placeholder="blurred"
            />
            <StaticImage
              width={330}
              src="../assets/images/solutions/building-information-modeling/image-11.png"
              alt="BIM modeling 3"
              title="BIM modeling 3"
              placeholder="blurred"
            />
          </div>
        </div>
      </NewsSectionBlock>
    </>
  )
}

export default BimPage
